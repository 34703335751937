/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Typography, Tabs, Breadcrumb, message, Modal, Button } from 'antd';
import { apiPost, apiGet, apiPut } from '../../../utils/api';
import { SURVEY, QUESTION, TAG, USER_FILTER } from '../../../constants/apiPath';
import { getLocalDateTime, getUTCDateString } from '../../../utils/date';

import SurveyForm from '../../../components/survey-form/survey-form.component';
import QuestionList from '../../../components/question-list/question-list.component';
import QuestionDrawer from '../../../components/question-drawer/question-drawer.component';
import PageLoader from '../../../components/shared/page-loader/page-loader.component';
import QuestionSelectorPopup from '../../../components/question-selector-popup/question-selector-popup.component';
import errorMessages from '../../../utils/condition-errors';

const { Title } = Typography;
const { TabPane } = Tabs;

const SurveyDetailPage = ({ match, history }) => {
  const [surveySubmitInProgress, setSurveySubmitInProgress] = useState(false);
  const [questionSubmitInProgress, setQuestionSubmitInProgress] = useState(
    false
  );
  const [surveyFetchInProgress, setSurveyFetchInProgress] = useState(false);
  const [tagFetchInProgress, setTagFetchInProgress] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isQuestionJumpModalOpen, setIsQuestionJumpModalOpen] = useState(false);
  const [selectedJumpAnswer, setSelectedJumpAnswer] = useState({});
  const [survey, setSurvey] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [conditionErrors, setConditionErrors] = useState(false);
  const uuid = match.params.uuid;
  const type = match.params.type;
  const title = uuid ? 'Kérdőív szerkesztés' : 'Kérdőív létrehozás';

  const getTagIds = tagLabels => {
    const tagIds = [];
    tagLabels.forEach(tagContent => {
      const origTag = tags.find(t => t.content === tagContent);
      tagIds.push(origTag.id);
    });
    return tagIds;
  };

  const handleJumpClick = ({ answer }) => {
    setSelectedJumpAnswer(answer);
    setIsQuestionJumpModalOpen(true);
  };

  const submitSurvey = async survey => {
    try {
      const data = { ...survey };
      setSurveySubmitInProgress(true);
      if (survey.tags) {
        data.tags = getTagIds(survey.tags);
      }
      if (survey.webTitle === '') {
        data.webTitle = null;
      }
      if (uuid) {
        await apiPut({
          url: `${SURVEY}/${uuid}`,
          data: {
            ...data,
            endAt: getUTCDateString(data.endAt),
            publishedAt: getUTCDateString(data.publishedAt),
          },
          showNotificaton: true,
        });
      } else {
        //profile does not have expiration
        if (type === 'profile') {
          data.publishedAt = new Date();
        }
        const newSurvey = await apiPost({
          url: SURVEY,
          data: {
            ...data,
            type: survey.type || type,
            endAt: getUTCDateString(data.endAt),
            publishedAt: getUTCDateString(data.publishedAt),
          },
          showNotificaton: true,
        });
        history.push(`/dashboard/survey/${newSurvey.uuid}`);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSurveySubmitInProgress(false);
    }
  };

  const refreshAnswerFromResponse = updatedAnswer => {
    setQuestions(
      questions.map(question => {
        const q = { ...question };
        q.answers = question.answers.map(answer => {
          if (answer.id === updatedAnswer.id) {
            return {
              ...answer,
              next_question_id: updatedAnswer.next_question_id,
              jumpToEnd: updatedAnswer.jumpToEnd,
            };
          }
          return { ...answer };
        });
        return q;
      })
    );
  };

  const submitAnswerJump = async ({ answerId, questionId = null }) => {
    try {
      const updatedAnswer = await apiPut({
        url: `${QUESTION}/answerjump`,
        data: { answerId, nextQuestionId: questionId },
        showNotificaton: true,
      });
      setIsQuestionJumpModalOpen(false);
      refreshAnswerFromResponse(updatedAnswer);
      // andate the answer object with the new jumped question reference
    } catch (err) {
      console.log(err);
    }
  };

  const submitSurveyClose = async ({ answer }) => {
    try {
      const updatedAnswer = await apiPut({
        url: `${QUESTION}/surveyclose`,
        data: { answerId: answer.id },
        showNotificaton: true,
      });
      refreshAnswerFromResponse(updatedAnswer);
    } catch (err) {
      console.log(err);
    }
  };

  const submitSurveySubQuestionAnswerMetaData = async ({
    question,
    answerId,
    subQuestionId,
    answerUUID,
  }) => {
    try {
      const subQuestion = question.subQuestions.find(
        x => x.id === subQuestionId
      );

      subQuestion.answerMetas = subQuestion.answerMetas || [];

      const subQuestionAnswerMeta = subQuestion.answerMetas.find(
        x => x.survey_answer_id === answerId
      );

      if (subQuestionAnswerMeta) {
        subQuestionAnswerMeta.jumpToEnd = true;
      } else {
        subQuestion.answerMetas.push({
          survey_answer_id: answerId,
          jumpToEnd: true,
          answerUUID: answerUUID,
        });
      }

      console.log(question);
      submitQuestion(question);
    } catch (err) {
      console.log(err);
    }
  };

  const submitQuestion = async (question, cb = () => {}) => {
    let isSuccess = false;
    try {
      setQuestionSubmitInProgress(true);
      if (!question?.tags[0]?.id) {
        question.tags = getTagIds(question.tags);
      } else {
        question.tags = getTagIds(question.tags.map(tag => tag.content));
      }
      //remove counter generated attribute
      const questionToSubmit = {
        ...question,
        counter: undefined,
        subQuestions: question.subQuestions?.map(subQuestion => ({
          ...subQuestion,
          voteCount: undefined,
        })),
        answers: question.answers.map(answers => ({
          ...answers,
          counter: undefined,
          voteCountsBySubQuestion: undefined,
        })),
      };

      var result;
      if (question.id) {
        result = await apiPut({
          url: QUESTION,
          data: { surveyUUID: uuid, question: questionToSubmit },
          showNotificaton: false,
        });
      } else {
        result = await apiPost({
          url: QUESTION,
          data: {
            surveyUUID: uuid,
            question: { ...questionToSubmit, order: questions.length },
          },
          showNotificaton: false,
        });
      }
      if (
        (result && !result.validationErrors) ||
        result.validationErrors.length === 0
      ) {
        message.success('Sikeres művelet');
        isSuccess = true;
        setDrawerOpen(false);
      } else {
        const conditionErrors = result.validationErrors.filter(
          x => x.errorMessageKey !== 'UNKNOWN_ERROR'
        );

        if (conditionErrors.length > 0) {
          debugger;
          setConditionErrors(conditionErrors);
        } else {
          message.error('Sikertelen művelet');
        }
      }
    } catch (err) {
      console.log(err);
      message.error('Sikertelen művelet');
    } finally {
      await fetchSurveyQuestions();
      setQuestionSubmitInProgress(false);
      cb(isSuccess);
    }
  };

  const fetchSurveyQuestions = useCallback(async () => {
    try {
      const questions = await apiGet({ url: `${QUESTION}/${uuid}` });
      setQuestions(questions);
    } catch (err) {
      console.log(err);
    }
  }, [uuid]);

  const modifyQuestionOrder = async (question, direction) => {
    try {
      let newOrder = null;
      if (question.order === 0 && direction === 'up') {
        return;
      } else if (question.order === questions.length && direction === 'down') {
        return;
      } else {
        newOrder = direction === 'up' ? question.order - 1 : question.order + 1;
      }
      const result = await apiPost({
        url: `${QUESTION}/order`,
        data: { surveyUUID: uuid, question, newOrder },
        showNotificaton: false,
      });

      if (
        (result && !result.validationErrors) ||
        result.validationErrors.length === 0
      ) {
        message.success('Sikeres művelet');
      } else {
        const conditionErrors = result.validationErrors.filter(
          x => x.errorMessageKey !== 'UNKNOWN_ERROR'
        );

        if (conditionErrors.length > 0) {
          setConditionErrors(conditionErrors);
        } else {
          message.error('Sikertelen művelet');
        }
      }
      fetchSurveyQuestions();
    } catch (err) {
      console.log(err);
      message.error('Sikertelen művelet');
    }
  };

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        setSurveyFetchInProgress(true);
        const survey = await apiGet({ url: `${SURVEY}/${uuid}` });
        setSurvey({
          ...survey,
          publishedAt: getLocalDateTime(survey.publishedAt),
          endAt: getLocalDateTime(survey.endAt),
        });
      } catch (err) {
        console.log(err);
      } finally {
        setSurveyFetchInProgress(false);
      }
    };
    const fetchTags = async () => {
      try {
        setTagFetchInProgress(true);
        const tagList = await apiGet({ url: TAG });
        const filterList = await apiGet({ url: `${USER_FILTER}/all` });
        setFilterOptions(
          filterList.map(filter => ({ value: filter.id, label: filter.name }))
        );
        setTags([...tagList]);
        setTagOptions(
          tagList.map(tag => ({ value: tag.content, label: tag.content }))
        );
      } catch (err) {
        console.log(err);
      } finally {
        setTagFetchInProgress(false);
      }
    };
    if (uuid && !survey) {
      fetchSurvey();
      fetchSurveyQuestions();
    }
    if (tags.length === 0 && !tagFetchInProgress) {
      fetchTags();
    }
  }, []);
  if (surveyFetchInProgress) {
    return <PageLoader />;
  }
  return (
    <div className="dashboard-survey-detail-page">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={'/dashboard/survey'}>Kérdőívek</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{title}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="dashboard-survey-detail-page__header">
        <div className="dashboard-survey-detail-page__header-title">
          <Title level={2}>{title}</Title>
        </div>
      </div>
      <div className="dashboard-survey-detail-page__tab">
        <Tabs defaultActiveKey="1" tabPosition={'top'}>
          <TabPane tab={'Alapadatok'} key={1}>
            <SurveyForm
              onSubmit={submitSurvey}
              type={type || survey?.type}
              isLoading={surveySubmitInProgress}
              survey={survey}
              tagList={tagOptions}
              filterList={filterOptions}
            />
          </TabPane>
          {uuid && (
            <TabPane tab={'Kérdések'} key={2}>
              {isQuestionJumpModalOpen && (
                <QuestionSelectorPopup
                  handleCancel={() => setIsQuestionJumpModalOpen(false)}
                  handleSave={submitAnswerJump}
                  answer={selectedJumpAnswer}
                  questions={questions}
                />
              )}
              <QuestionList
                questions={questions}
                handleEditClick={question => {
                  setSelectedQuestion(question);
                  setDrawerOpen(true);
                }}
                handleOrderClick={modifyQuestionOrder}
                handleDeleteClick={question =>
                  submitQuestion({ ...question, deleted: true })
                }
                handleAdd={() => setDrawerOpen(true)}
                handleJumpClick={handleJumpClick}
                handleSurveyCloseClick={submitSurveyClose}
                handleSurveyCloseForSubQuestionClick={
                  submitSurveySubQuestionAnswerMetaData
                }
              />
              {drawerOpen && (
                <QuestionDrawer
                  isOpen={drawerOpen}
                  initialQuestion={selectedQuestion}
                  loading={questionSubmitInProgress}
                  onClose={() => {
                    setSelectedQuestion({});
                    setDrawerOpen(false);
                  }}
                  handleSubmit={submitQuestion}
                  tagList={tagOptions}
                  questions={questions}
                />
              )}
            </TabPane>
          )}
        </Tabs>
        <Modal
          zIndex={10000}
          visible={conditionErrors?.length > 0}
          title="Sikertelen művelet"
          onCancel={() => setConditionErrors([])}
          footer={
            <Button type="primary" onClick={() => setConditionErrors([])}>
              <span>OK</span>
            </Button>
          }
        >
          {conditionErrors?.length > 0 && (
            <>
              {conditionErrors.map((error, index) => (
                <div key={index}>
                  {errorMessages[error.errorMessageKey]}:
                  {questions.map(question => {
                    if (
                      question.id === error.questionId ||
                      (!error.questionId && question.id === selectedQuestion.id)
                    ) {
                      return (
                        <div key={question.id}>
                          <span>
                            Kérdés (amihez a feltétel tartozik):{' '}
                            {question.content}
                          </span>
                          <br></br>
                          {error.groupNo !== undefined && (
                            <span>Feltétel csoport: {error.groupNo + 1}.</span>
                          )}
                          <span> </span>
                          {error.sequenceNo !== undefined && (
                            <span>Feltétel: {error.sequenceNo + 1}.</span>
                          )}
                        </div>
                      );
                    }
                    return null;
                  })}
                  {index !== conditionErrors.length - 1 && (
                    <hr
                      style={{
                        margin: '10px 0',
                      }}
                    />
                  )}
                </div>
              ))}
            </>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default withRouter(SurveyDetailPage);
