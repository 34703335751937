import React, { useState, useEffect } from 'react';
import { Button, InputNumber, Select } from 'antd';
import {
  CheckOutlined,
  MinusCircleTwoTone,
  PlusOutlined,
} from '@ant-design/icons';

import './question-condition-editor.style.css';

import { ReactComponent as LessThan } from '../../assets/condition-operators/less-than.svg';
import { ReactComponent as LessThanEqual } from '../../assets/condition-operators/less-than-equal.svg';
import { ReactComponent as Equals } from '../../assets/condition-operators/equals.svg';
import { ReactComponent as NotEqual } from '../../assets/condition-operators/not-equal.svg';
import { ReactComponent as GreaterThanEqual } from '../../assets/condition-operators/greater-than-equal.svg';
import { ReactComponent as GreaterThan } from '../../assets/condition-operators/greater-than.svg';
import { ReactComponent as In } from '../../assets/condition-operators/in.svg';
import { ReactComponent as NotIn } from '../../assets/condition-operators/not-in.svg';

const QuestionConditionEditor = ({
  initialQuestion,
  conditions,
  questions,
  validateCondition,
  onChange,
  onRemove,
}) => {
  const [conditionGroups, setConditionGroups] = useState([]);
  const [isEvaluationOpenAt, setIsEvaluationOpenAt] = useState(undefined);

  useEffect(() => {
    if (!!conditions) {
      updateConditionGroups({ conditions: conditions });
    }
  }, [conditions]);

  const updateConditionGroups = ({ conditions }) => {
    const groupedConditions = conditions.reduce((acc, condition) => {
      if (acc[condition.groupNo]) {
        acc[condition.groupNo].push(condition);
      } else {
        acc[condition.groupNo] = [condition];
      }
      return acc;
    }, {});
    setConditionGroups(groupedConditions);
  };

  const addConditionGroup = () => {
    const index = Object.keys(conditionGroups).length;
    addCondition(index);
  };

  const removeConditionGroup = groupNo => {
    onRemove(conditionGroups[groupNo]);
  };

  const addCondition = groupNo => {
    const nextSequenceIndex = conditionGroups[groupNo]?.length || 0;

    const newCondition = {
      groupNo,
      sequenceNo: nextSequenceIndex,
      survey_question_id: initialQuestion.id,
      type: initialQuestion.order === 0 ? 'PROFILE_DATA' : 'QUESTION_ANSWER',
    };

    onChange(newCondition);
  };

  const removeCondition = condition => {
    onRemove([condition]);
  };

  const updateType = ({ condition, type }) => {
    if (condition.type !== type) {
      condition.type = type;
      condition.op1_question_id = null;
      condition.op1_profile_attribute = null;
      condition.op2_answer_id = null;
      condition.op1_sub_question_id = null;
      condition.operator = null;
      onChange(condition);
    }
  };

  const updateOp1QuestionId = ({ condition, questionId }) => {
    if (condition.op1_question_id !== questionId) {
      condition.op1_question_id = questionId;
      condition.op1_sub_question_id = null;
      condition.op2_answer_id = null;
      condition.operator = null;
      onChange(condition);
    }
  };

  const updateOp1SubQuestionId = ({ condition, subQuestionId }) => {
    if (condition.op1_sub_question_id !== subQuestionId) {
      condition.op1_sub_question_id = subQuestionId;
      onChange(condition);
    }
  };

  const updateOp2AnswerId = ({ condition, answerId }) => {
    if (condition.op2_answer_id !== answerId) {
      condition.op2_answer_id = answerId;
      onChange(condition);
    }
  };

  const updateOperator = ({ condition, operator }) => {
    if (condition.operator !== operator) {
      condition.operator = operator;
      onChange(condition);
    }
  };

  const updateProfileAttribute = ({ condition, profileAttribute }) => {
    if (condition.op1_profile_attribute !== profileAttribute) {
      condition.op1_profile_attribute = profileAttribute;
      condition.op2_value_str = null;
      condition.op2_value_int = null;
      condition.operator = null;
      onChange(condition);
    }
  };

  const updateOp2ValueStr = ({ condition, value }) => {
    if (condition.op2_value_str !== value) {
      condition.op2_value_str = value;
      onChange(condition);
    }
  };

  const updateOp2ValueInt = ({ condition, value }) => {
    if (condition.op2_value_int !== value) {
      condition.op2_value_int = value;
      onChange(condition);
    }
  };

  const getAvailableTypes = () => {
    if (initialQuestion.order === 0) {
      return [{ value: 'PROFILE_DATA', label: 'Profil' }];
    } else {
      return [
        { value: 'QUESTION_ANSWER', label: 'Kérdés' },
        { value: 'PROFILE_DATA', label: 'Profil' },
      ];
    }
  };

  const getAvailableConditionQuestions = ({ condition }) => {
    if (condition.type === 'QUESTION_ANSWER') {
      if (!initialQuestion.id) {
        return questions.filter(
          question => question.type !== 'custom' && question.type !== 'ordered'
        );
      }
      return questions.filter(
        question =>
          question.order < initialQuestion.order &&
          question.type !== 'custom' &&
          question.type !== 'ordered'
      );
    }
    return [];
  };

  const getAvailableConditionSubQuestions = ({ condition }) => {
    if (condition.type === 'QUESTION_ANSWER') {
      const question = questions.find(
        question => question.id === condition.op1_question_id
      );
      return question?.subQuestions || [];
    }
    return [];
  };

  const getAvailableProfileAttributes = ({ condition }) => {
    if (condition.type === 'PROFILE_DATA') {
      return [
        { value: 'BIRTH_YEAR', label: 'Születési év' },
        { value: 'COUNTY', label: 'Megye' },
        { value: 'EDUCATION_LEVEL', label: 'Végzettség' },
        { value: 'SETTLEMENT_TYPE', label: 'Település típus' },
        { value: 'GENDER', label: 'Nem' },
      ];
    }
    return [];
  };

  const getAvailableConditionOperators = ({ condition }) => {
    if (condition.type === 'PROFILE_DATA') {
      switch (condition.op1_profile_attribute) {
        case 'BIRTH_YEAR':
          return ['LT', 'LE', 'GE', 'GT'];
        case 'COUNTY':
        case 'EDUCATION_LEVEL':
        case 'SETTLEMENT_TYPE':
        case 'GENDER':
          return ['EQ', 'NE'];
        default:
          return [];
      }
    } else if (condition.type === 'QUESTION_ANSWER') {
      const question = questions.find(
        question => question.id === condition.op1_question_id
      );

      if (!!question) {
        switch (question.type) {
          case 'multichoice':
            return ['IN', 'NIN'];
          case 'normal':
          case 'slider':
          case 'table':
            return ['LT', 'LE', 'EQ', 'NE', 'GE', 'GT'];
          default:
            return [];
        }
      }
    }
    return [];
  };

  const getAvailableAnswerOptions = ({ condition }) => {
    if (condition.type === 'QUESTION_ANSWER') {
      const question = questions.find(
        question => question.id === condition.op1_question_id
      );
      return question?.answers || [];
    }
    return [];
  };

  const getAvailableProfileAttributeValues = ({ condition }) => {
    if (condition.type === 'PROFILE_DATA') {
      switch (condition.op1_profile_attribute) {
        case 'COUNTY':
          return [
            { value: 'bk', label: 'Bács-Kiskun' },
            { value: 'baranya', label: 'Baranya' },
            { value: 'bekes', label: 'Békés' },
            { value: 'baz', label: 'Borsod-Abaúj-Zemplén' },
            { value: 'cscs', label: 'Csongrád-Csanád' },
            { value: 'fejer', label: 'Fejér' },
            { value: 'gyms', label: 'Győr-Moson-Sopron' },
            { value: 'hb', label: 'Hajdú-Bihar' },
            { value: 'heves', label: 'Heves' },
            { value: 'jnsz', label: 'Jász-Nagykun-Szolnok' },
            { value: 'ke', label: 'Komárom-Esztergom' },
            { value: 'nograd', label: 'Nógrád' },
            { value: 'pest', label: 'Pest' },
            { value: 'somogy', label: 'Somogy' },
            { value: 'szszb', label: 'Szabolcs-Szatmár-Bereg' },
            { value: 'tolna', label: 'Tolna' },
            { value: 'vas', label: 'Vas' },
            { value: 'veszprem', label: 'Veszprém' },
            { value: 'zala', label: 'Zala' },
            { value: 'budapest', label: 'Budapest' },
            { value: 'nem_mo', label: 'Nem Magyarország' },
          ];
        case 'EDUCATION_LEVEL':
          return [
            { value: '8_altalanos', label: '8 általános' },
            { value: 'szakmunkas', label: 'Szakmunkás' },
            { value: 'szakkozep', label: 'Szakközép' },
            { value: 'gimnazium', label: 'Gimnáziumi érettségi' },
            { value: 'felsooktatas', label: 'Főiskola/egyetemi diploma' },
          ];
        case 'SETTLEMENT_TYPE':
          return [
            { value: 'fovaros', label: 'Budapest' },
            {
              value: 'megyeszekhely',
              label: 'Megyeszékhely vagy megyei jogú város',
            },
            { value: 'varos', label: 'Város' },
            { value: 'kozseg', label: 'Község' },
            { value: 'nem_mo', label: 'Nem Magyarország' },
          ];
        case 'GENDER':
          return [
            { value: 'male', label: 'Férfi' },
            { value: 'female', label: 'Nő' },
          ];
        default:
          return [];
      }
    }
  };

  const attributeHasStringValues = attribute => {
    return ['COUNTY', 'EDUCATION_LEVEL', 'SETTLEMENT_TYPE', 'GENDER'].includes(
      attribute
    );
  };

  const getOperatorMeta = (operator, condition) => {
    const className = operator === condition.operator ? 'active' : 'inactive';
    switch (operator) {
      case 'LT':
        return {
          label: 'kisebb mint',
          icon: <LessThan className={className} />,
        };
      case 'LE':
        return {
          label: 'kisebb vagy egyenlő',
          icon: <LessThanEqual className={className} />,
        };
      case 'EQ':
        return {
          label: 'egyenlő',
          icon: <Equals className={className} />,
        };
      case 'NE':
        return {
          label: 'nem egyenlő',
          icon: <NotEqual className={className} />,
        };

      case 'GE':
        return {
          label: 'nagyobb vagy egyenlő',
          icon: <GreaterThanEqual className={className} />,
        };
      case 'GT':
        return {
          label: 'nagyobb mint',
          icon: <GreaterThan className={className} />,
        };
      case 'IN':
        return {
          label: 'tartalmazza',
          icon: <In className={className} />,
        };
      case 'NIN':
        return {
          label: 'nem tartalmazza',
          icon: <NotIn className={className} />,
        };
      default:
        return undefined;
    }
  };

  const getEvaluations = condition => {
    const result = [];
    if (!validateCondition(condition)) {
      if (!!condition.op2_answer_id) {
        const answers = getAvailableAnswerOptions({ condition });
        answers.map(answer => {
          const op2Answer = answers.find(
            answer => answer.id === condition.op2_answer_id
          );
          result.push({
            answerId: answer.id,
            content: answer.content,
            isTrue: evaluateOperation(
              condition.operator,
              answer.order,
              op2Answer.order
            ),
          });
        });
      } else if (!!condition.op2_value_str) {
        const values = getAvailableProfileAttributeValues({ condition });
        values.map(value => {
          result.push({
            content: value.label,
            isTrue: evaluateOperation(
              condition.operator,
              value.value,
              condition.op2_value_str
            ),
          });
        });
      }
    }
    return result;
  };

  const evaluateOperation = (operator, value1, value2) => {
    switch (operator) {
      case 'LT':
        return value1 < value2;
      case 'LE':
        return value1 <= value2;
      case 'EQ':
        return value1 === value2;
      case 'NE':
        return value1 !== value2;
      case 'GE':
        return value1 >= value2;
      case 'GT':
        return value1 > value2;
      case 'IN':
        return value1 === value2 ? true : undefined;
      case 'NIN':
        return value1 !== value2 ? undefined : false;
      default:
        return false;
    }
  };

  return (
    <>
      {conditions.length === 0 && <span> Mindig megjelenik</span>}
      {!!conditionGroups && (
        <>
          <div className="conditions-container">
            {Object.keys(conditionGroups).map((conditionGroup, groupIdx) => (
              <React.Fragment key={groupIdx}>
                <div className="condition-group-container">
                  <div className="condition-group-title">
                    <span>Feltételcsoport {groupIdx + 1}</span>
                    <MinusCircleTwoTone
                      twoToneColor="red"
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        removeConditionGroup(conditionGroup);
                      }}
                    />
                  </div>
                  {conditionGroups[conditionGroup].map(
                    (condition, conditionIdx) => (
                      <React.Fragment key={conditionIdx}>
                        <div className="condition-item">
                          <Select
                            className="selector"
                            value={condition.type}
                            onChange={value => {
                              updateType({ condition, type: value });
                            }}
                            options={getAvailableTypes()}
                          ></Select>
                          {condition.type === 'QUESTION_ANSWER' && (
                            <Select
                              className="selector"
                              placeholder="Kérem válasszon"
                              value={condition.op1_question_id}
                              onChange={value => {
                                updateOp1QuestionId({
                                  condition,
                                  questionId: value,
                                });
                              }}
                              options={getAvailableConditionQuestions({
                                condition,
                              }).map(question => ({
                                value: question.id,
                                label: question.content,
                              }))}
                            ></Select>
                          )}
                          {condition.type === 'PROFILE_DATA' && (
                            <Select
                              className="selector"
                              placeholder="Kérem válasszon"
                              value={condition.op1_profile_attribute}
                              onChange={value => {
                                updateProfileAttribute({
                                  condition,
                                  profileAttribute: value,
                                });
                              }}
                              options={getAvailableProfileAttributes({
                                condition,
                              })}
                            ></Select>
                          )}
                          <Select
                            className="selector"
                            placeholder="Kérem válasszon"
                            value={condition.op1_sub_question_id}
                            style={{
                              visibility:
                                questions.filter(
                                  question =>
                                    question.id === condition.op1_question_id
                                )[0]?.type === 'table'
                                  ? 'visible'
                                  : 'hidden',
                            }}
                            onChange={value => {
                              updateOp1SubQuestionId({
                                condition,
                                subQuestionId: value,
                              });
                            }}
                            options={getAvailableConditionSubQuestions({
                              condition,
                            }).map(subQuestion => ({
                              value: subQuestion.id,
                              label: subQuestion.content,
                            }))}
                          ></Select>
                          <div>
                            <div className="operatorSelector">
                              {getAvailableConditionOperators({
                                condition,
                              }).map(operator => (
                                <React.Fragment key={operator}>
                                  <button
                                    onClick={() => {
                                      updateOperator({ condition, operator });
                                    }}
                                    className="operatorButton"
                                  >
                                    {getOperatorMeta(operator, condition).icon}
                                  </button>
                                </React.Fragment>
                              ))}
                            </div>
                            {!!condition.operator &&
                              !!getOperatorMeta(
                                condition.operator,
                                condition
                              ) && (
                                <span className="operatorLabel">
                                  {
                                    getOperatorMeta(
                                      condition.operator,
                                      condition
                                    ).label
                                  }
                                </span>
                              )}
                          </div>
                          {!condition.op1_profile_attribute && (
                            <Select
                              style={{
                                visibility: !!condition.op1_question_id
                                  ? 'visible'
                                  : 'hidden',
                              }}
                              className="selector"
                              placeholder="Kérem válasszon"
                              value={condition.op2_answer_id}
                              onChange={value => {
                                updateOp2AnswerId({
                                  condition,
                                  answerId: value,
                                });
                              }}
                              options={getAvailableAnswerOptions({
                                condition,
                              }).map(answer => ({
                                value: answer.id,
                                label: answer.content,
                              }))}
                            ></Select>
                          )}
                          {condition.type === 'PROFILE_DATA' &&
                            !!condition.op1_profile_attribute &&
                            attributeHasStringValues(
                              condition.op1_profile_attribute
                            ) && (
                              <Select
                                className="selector"
                                placeholder="Kérem válasszon"
                                value={condition.op2_value_str}
                                onChange={value => {
                                  updateOp2ValueStr({
                                    condition,
                                    value,
                                  });
                                }}
                                options={getAvailableProfileAttributeValues({
                                  condition,
                                })}
                              ></Select>
                            )}
                          {condition.type === 'PROFILE_DATA' &&
                            !!condition.op1_profile_attribute &&
                            !attributeHasStringValues(
                              condition.op1_profile_attribute
                            ) && (
                              <div>
                                <InputNumber
                                  style={{
                                    width: '100%',
                                  }}
                                  min={1}
                                  value={condition.op2_value_int}
                                  onChange={e => {
                                    updateOp2ValueInt({
                                      condition,
                                      value: e,
                                    });
                                  }}
                                />
                              </div>
                            )}
                          <div className="conditionButtonContainer">
                            <div className="conditionButton">
                              <CheckOutlined
                                style={{
                                  visibility:
                                    getEvaluations(condition).length > 0
                                      ? 'visible'
                                      : 'hidden',
                                }}
                                onClick={() => {
                                  isEvaluationOpenAt?.sequenceNo ===
                                    condition.sequenceNo &&
                                  isEvaluationOpenAt?.groupNo ===
                                    condition.groupNo
                                    ? setIsEvaluationOpenAt(undefined)
                                    : setIsEvaluationOpenAt({
                                        sequenceNo: condition.sequenceNo,
                                        groupNo: condition.groupNo,
                                      });
                                }}
                              />
                              {isEvaluationOpenAt?.sequenceNo ===
                                condition.sequenceNo &&
                                isEvaluationOpenAt?.groupNo ===
                                  condition.groupNo && (
                                  <div className="conditionEvaluation">
                                    {getEvaluations(condition).map(
                                      (evaluation, idx) => (
                                        <div key={idx}>
                                          <span
                                            style={{
                                              color:
                                                evaluation.isTrue === false
                                                  ? 'red'
                                                  : evaluation.isTrue === true
                                                  ? 'green'
                                                  : 'gray',
                                            }}
                                          >
                                            {evaluation.content}
                                          </span>
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                            </div>
                            <div className="conditionButton">
                              <MinusCircleTwoTone
                                twoToneColor="red"
                                onClick={() => {
                                  removeCondition(condition);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {conditionIdx !==
                          conditionGroups[conditionGroup].length - 1 && (
                          <span>ÉS</span>
                        )}
                      </React.Fragment>
                    )
                  )}
                  <Button
                    type="danger"
                    onClick={() => {
                      addCondition(parseInt(conditionGroup));
                    }}
                  >
                    <PlusOutlined />
                    Feltétel hozzáadása
                  </Button>
                </div>
                {groupIdx !== Object.keys(conditionGroups).length - 1 && (
                  <span>VAGY</span>
                )}
              </React.Fragment>
            ))}
          </div>
        </>
      )}
      {(initialQuestion.order > 0 ||
        (initialQuestion.order !== 0 && questions.length > 0)) && (
        <Button
          type="danger"
          onClick={() => {
            addConditionGroup();
          }}
        >
          <PlusOutlined />
          Feltételcsoport hozzáadása
        </Button>
      )}
      {(initialQuestion.order === 0 ||
        (!initialQuestion.order && questions.length === 0)) && (
        <span>A kérdőív legelső kérdéséhez nem lehet feltételt rögzíteni!</span>
      )}
    </>
  );
};

export default QuestionConditionEditor;
