import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Input,
  Typography,
  Divider,
  Button,
  InputNumber,
  Select,
  message,
  Checkbox,
  notification,
} from 'antd';
import {
  MinusCircleTwoTone,
  PlusOutlined,
  DownCircleTwoTone,
  UpCircleTwoTone,
} from '@ant-design/icons';
import ImageUpload from '../shared/image-upload/image-upload.component';
import VideoUpload from '../shared/video-upload/video-upload.component';

import { getBase64 } from '../../utils/base64';

import './question-drawer.style.css';
import QuestionConditionEditor from '../question-condition-editor/question-condition-editor.component';

const { Title, Text } = Typography;
const { Option } = Select;

const AnswerItem = ({
  answer,
  handleChange,
  handleRemove,
  handleOrder,
  questionType,
}) => {
  return (
    <div className="drawer-answer-item">
      <div className="drawer-answer-item__answer">
        <Input
          placeholder="Válasz"
          value={answer.content}
          onChange={e =>
            handleChange({
              field: 'content',
              value: e.target.value,
              order: answer.order,
            })
          }
        />

        <UpCircleTwoTone
          className="drawer-answer-item__icon"
          onClick={() =>
            handleOrder({ oldOrder: answer.order, newOrder: answer.order - 1 })
          }
        />
        <DownCircleTwoTone
          className="drawer-answer-item__icon"
          onClick={() =>
            handleOrder({ oldOrder: answer.order, newOrder: answer.order + 1 })
          }
        />
        <MinusCircleTwoTone
          className="drawer-answer-item__icon"
          twoToneColor="#eb2f96"
          onClick={() => handleRemove({ order: answer.order })}
        />
      </div>
      {questionType === 'multichoice' && (
        <div className="drawer-answer-item__check">
          <Checkbox
            checked={answer.exclusive}
            onChange={e => {
              handleChange({
                field: 'exclusive',
                value: e.target.checked,
                order: answer.order,
              });
            }}
          >
            Kizárólagos
          </Checkbox>
        </div>
      )}
      {questionType !== 'ordered' &&
        questionType !== 'slider' &&
        questionType !== 'table' && (
          <div className="drawer-answer-item__check">
            <Checkbox
              checked={answer.isRight}
              onChange={e => {
                handleChange({
                  field: 'isRight',
                  value: e.target.checked,
                  order: answer.order,
                });
              }}
            >
              Helyes
            </Checkbox>
          </div>
        )}
      {questionType !== 'ordered' &&
        questionType !== 'slider' &&
        questionType !== 'table' && (
          <>
            <div className="drawer-answer-item__image">
              <Text>Videó:</Text>
              <VideoUpload
                onVideoLoaded={file => {
                  getBase64(file, result => {
                    handleChange({
                      field: 'video',
                      value: result,
                      order: answer.order,
                    });
                  });
                }}
                url={answer.video}
                handleDelete={() => {
                  handleChange({
                    field: 'video',
                    value: null,
                    order: answer.order,
                  });
                }}
              />
            </div>
            <div className="drawer-answer-item__image">
              <Text>Kép:</Text>
              <ImageUpload
                onImageLoaded={file => {
                  getBase64(file, result => {
                    handleChange({
                      field: 'image',
                      value: result,
                      order: answer.order,
                    });
                  });
                }}
                url={answer.image}
                handleDelete={() => {
                  handleChange({
                    field: 'image',
                    value: null,
                    order: answer.order,
                  });
                }}
              />
            </div>
          </>
        )}
    </div>
  );
};

const SubQuestionItem = ({
  subQuestion,
  handleChange,
  handleOrder,
  handleRemove,
}) => {
  return (
    <div className="drawer-sub-question-item">
      <div className="drawer-sub-question-item__sub-question">
        <Input
          placeholder="Alkérdés"
          value={subQuestion.content}
          onChange={e =>
            handleChange({ value: e.target.value, order: subQuestion.order })
          }
        />
        <UpCircleTwoTone
          className="drawer-sub-question-item__icon"
          onClick={() =>
            handleOrder({
              oldOrder: subQuestion.order,
              newOrder: subQuestion.order - 1,
            })
          }
        />
        <DownCircleTwoTone
          className="drawer-sub-question-item__icon"
          onClick={() =>
            handleOrder({
              oldOrder: subQuestion.order,
              newOrder: subQuestion.order + 1,
            })
          }
        />
        <MinusCircleTwoTone
          className="drawer-sub-question-item__icon"
          twoToneColor="#eb2f96"
          onClick={() => handleRemove({ order: subQuestion.order })}
        />
      </div>
    </div>
  );
};

const QuestionDrawer = ({
  isOpen = false,
  onClose = () => { },
  handleSubmit = () => { },
  initialQuestion = {},
  tagList,
  loading,
  questions,
}) => {
  const [answers, setAnswers] = useState(initialQuestion.answers || []);
  const [type, setType] = useState(initialQuestion.type || 'normal');
  const [questionTags, setQuestionTags] = useState([]);
  const [questionImageBase64, setQuestionImageBase64] = useState(null);
  const [questionVideoBase64, setQuestionVideoBase64] = useState(null);
  const [content, setContent] = useState(initialQuestion.content || '');
  const [image, setImage] = useState(initialQuestion.image);
  const [video, setVideo] = useState(initialQuestion.video);
  const [point, setPoint] = useState(initialQuestion.point || 1);
  const [maxAnswerCount, setMaxAnswerCount] = useState(
    initialQuestion.maxAnswerCount || 1
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [random, setRandom] = useState(initialQuestion.random);
  const [hideresult, setHideresult] = useState(initialQuestion.hideresult);
  const [webFeatured, setWebFeatured] = useState(initialQuestion.webFeatured);
  const [randomLimit, setRandomLimit] = useState(
    initialQuestion.randomLimit || 1
  );
  const [copiedAnswers, setCopiedAnswers] = useState([]);

  const [subQuestionSkippable, setSubQuestionSkippable] = useState(
    initialQuestion.subQuestionSkippable || false
  );
  const [skipText, setSkipText] = useState(
    initialQuestion.skipText || undefined
  );
  const [subQuestions, setSubQuestions] = useState(
    !initialQuestion.subQuestions
      ? []
      : initialQuestion.subQuestions.sort((a, b) => a.order - b.order)
  );
  const [conditions, setConditions] = useState(
    initialQuestion.conditions || []
  );

  const [hideAddAnswerButton, setHideAddAnswerButton] = useState(false);

  const clearState = () => {
    setQuestionTags([]);
    setAnswers([]);
    setContent('');
    setPoint(1);
    setMaxAnswerCount(1);
    setQuestionImageBase64(null);
    setImage(null);
    setQuestionVideoBase64(null);
    setVideo(null);
    setRandom(false);
    setType('normal');
    setSubQuestionSkippable(false);
    setSkipText(undefined);
    setSubQuestions([]);
    setHideAddAnswerButton(false);
    setConditions([]);
  };

  const setStateToInitial = () => {
    if (Object.keys(initialQuestion).length > 0) {
      setAnswers(initialQuestion.answers);
      setContent(initialQuestion.content);
      setPoint(initialQuestion.point);
      setType(initialQuestion.type);
      setImage(initialQuestion.image);
      setVideo(initialQuestion.video);
      setRandom(initialQuestion.random);
      setHideresult(initialQuestion.hideresult);
      setWebFeatured(initialQuestion.webFeatured);
      setMaxAnswerCount(initialQuestion.maxAnswerCount);
      setRandomLimit(initialQuestion.randomLimit);
      setSubQuestionSkippable(initialQuestion.subQuestionSkippable);
      setSkipText(initialQuestion.skipText);
      setSubQuestions(initialQuestion.subQuestions);
      setConditions(initialQuestion.conditions ?? []);

      // get the selected id-s
      if (initialQuestion.tags) {
        setQuestionTags(initialQuestion.tags.map(tag => tag.content));
      }

      if (initialQuestion.answers.length >= 10) {
        setHideAddAnswerButton(true);
      }
    } else {
      clearState();
    }
  };

  useEffect(() => {
    setStateToInitial();
  }, [initialQuestion, isOpen]);

  useEffect(() => {
    const clipBoardAnswers = localStorage.getItem('answer_clipboard');
    if (clipBoardAnswers) {
      setCopiedAnswers(JSON.parse(clipBoardAnswers));
    }
  }, []);

  const answerChange = ({ value, order, field }) => {
    setAnswers(
      answers.map((answer, index) => {
        if (index === order) {
          return { ...answer, [field]: value };
        }
        return { ...answer };
      })
    );
  };

  const copyAnswers = () => {
    const answersToCopy = answers.map(({ content, order }) => {
      return {
        content,
        order,
      };
    });
    localStorage.setItem('answer_clipboard', JSON.stringify(answersToCopy));
    notification.open({
      message: 'Válaszok másolva',
    });
  };

  const injectAnswers = () => {
    setAnswers([...copiedAnswers]);
  };

  useEffect(() => {
    if (!initialQuestion.maxAnswerCount) {
      setMaxAnswerCount(answers.length);
    }

    if (!initialQuestion.randomLimit && type !== 'table') {
      setRandomLimit(answers.length);
    }
  }, [
    answers,
    initialQuestion.maxAnswerCount,
    initialQuestion.randomLimit,
    type,
  ]);

  useEffect(() => {
    if (!initialQuestion.randomLimit && type === 'table') {
      setRandomLimit(subQuestions.length);
    }
  }, [subQuestions, initialQuestion.randomLimit, type]);

  useEffect(() => {
    if (type === 'table') {
      if (answers.length < 10) {
        setHideAddAnswerButton(false);
      } else {
        setHideAddAnswerButton(true);
        if (answers.length > 10) {
          setAnswers(answers.slice(0, 10));
        }
      }
    }
  }, [answers.length, type]);

  const answerOrder = ({ oldOrder, newOrder }) => {
    if (newOrder < 0 || newOrder > answers.length) {
      return;
    }
    setAnswers(
      answers
        .map((answer, index) => {
          if (answer.order === newOrder) {
            return { ...answer, order: oldOrder };
          } else if (answer.order === oldOrder) {
            return { ...answer, order: newOrder };
          }
          return { ...answer };
        })
        .sort((a, b) => a.order - b.order)
    );
  };
  const answerRemove = ({ order }) => {
    setAnswers(
      answers
        .filter(answer => answer.order !== order)
        .map((answer, index) => {
          return { ...answer, order: index };
        })
    );
  };

  const validateCondition = condition => {
    if (condition.type === 'QUESTION_ANSWER') {
      if (!condition.op1_question_id) {
        return 'Hiányzik a feltételhez tartozó kérdés';
      }

      if (!condition.operator) {
        return 'Hiányzik a feltételhez tartozó operátor';
      }

      if (!condition.op2_answer_id) {
        return 'Hiányzik a feltételhez tartozó válasz';
      }

      const question = questions.find(
        question => question.id === condition.op1_question_id
      );

      if (!question) {
        return 'A feltételhez tartozó kérdés nem található';
      }

      if (question.type === 'table' && !condition.op1_sub_question_id) {
        return 'Hiányzik a feltételhez tartozó alkérdés';
      }

      if (question.type === 'custom' || question.type === 'ordered') {
        return 'A feltételhez tartozó kérdés típusa nem támogatott';
      }
    } else if (condition.type === 'PROFILE_DATA') {
      if (!condition.op1_profile_attribute) {
        return 'Hiányzik a feltételhez tartozó profil attribútum';
      }

      if (!condition.operator) {
        return 'Hiányzik a feltételhez tartozó operátor';
      }

      if (!condition.op2_value_int && !condition.op2_value_str) {
        return 'Hiányzik a feltételhez tartozó érték';
      }
    }
  };

  const submitQuestion = () => {
    if (!content) {
      message.error('Kérdés mező nem lehet üres');
      return;
    }
    if (
      answers &&
      answers.find(answer => !answer.content && !answer.image && !answer.video)
    ) {
      message.error('Üres válasz nem vehető fel');
      return;
    }
    if (point < 0) {
      message.error('A pont értéke nem lehet negatív');
      return;
    }
    if (type === 'table') {
      if (subQuestions.length === 0) {
        message.error('Legalább egy alkérdés szükséges');
        return;
      }

      if (answers.length > 10) {
        message.error('Maximum 10 válasz opciót lehet megadni');
        return;
      }
    }

    for (let i = 0; i < conditions.length; i++) {
      const error = validateCondition(conditions[i]);
      if (error) {
        message.error(error);
        return;
      }
    }

    const skipTextOrDefault = !skipText ? 'Nem tudom' : skipText;

    const question = {
      content,
      type,
      point,
      maxAnswerCount,
      tags: questionTags,
      answers,
      random,
      randomLimit,
      hideresult: hideresult ?? false,
      webFeatured: webFeatured,
      image: questionImageBase64 ? questionImageBase64 : image,
      video: questionVideoBase64 ? questionVideoBase64 : video,
      subQuestionSkippable,
      skipText: subQuestionSkippable ? skipTextOrDefault : undefined,
      subQuestions: type === 'table' ? subQuestions : undefined,
      conditions,
    };
    handleSubmit({ ...initialQuestion, ...question });
  };

  const handleTagChange = value => {
    setQuestionTags(value);
  };

  const subQuestionRemove = ({ order }) => {
    setSubQuestions(
      subQuestions
        .filter(subQuestion => subQuestion.order !== order)
        .map((subQuestion, index) => {
          return { ...subQuestion, order: index };
        })
    );
  };

  const subQuestionChange = ({ value, order }) => {
    setSubQuestions(
      subQuestions.map((sq, index) => {
        if (index === order) {
          return { ...sq, content: value };
        }
        return { ...sq };
      })
    );
  };

  const subQuestionOrder = ({ oldOrder, newOrder }) => {
    if (newOrder < 0 || newOrder > subQuestions.length) {
      return;
    }
    setSubQuestions(
      subQuestions
        .map((sq, index) => {
          if (sq.order === newOrder) {
            return { ...sq, order: oldOrder };
          } else if (sq.order === oldOrder) {
            return { ...sq, order: newOrder };
          }
          return { ...sq };
        })
        .sort((a, b) => a.order - b.order)
    );
  };
  return (
    <Drawer
      width={'80%'}
      placement="right"
      closable={true}
      maskClosable={false}
      onClose={onClose}
      visible={isOpen}
      className="question-drawer"
      data-testid="question-drawer"
    >
      <div className="question-container">
        <Title level={4}>Kérdés</Title>
        <Input
          placeholder="Kérdés"
          value={content}
          onChange={e => setContent(e.target.value)}
        />
        <br />
        <Text>Típus:</Text>
        <Select
          placeholder="Kérem válaszon"
          onChange={value => setType(value)}
          value={type}
        >
          <Option value="normal">Normál</Option>
          <Option value="multichoice">Több válasz</Option>
          <Option value="custom">Szabadszavas</Option>
          <Option value="ordered">Rangsorolós</Option>
          <Option value="slider">Csúszkás</Option>
          <Option value="table">Táblázatos</Option>
        </Select>
        <br />
        <Text>Kép:</Text>
        <ImageUpload
          onImageLoaded={file =>
            getBase64(file, result => setQuestionImageBase64(result))
          }
          url={image}
          handleDelete={() => {
            setImage(null);
            setQuestionImageBase64(null);
          }}
        />
        <br />
        <Text>Videó:</Text>
        <VideoUpload
          onVideoLoaded={file =>
            getBase64(file, result => setQuestionVideoBase64(result))
          }
          url={video}
          handleDelete={() => {
            setVideo(null);
            setQuestionVideoBase64(null);
          }}
        />
        <br />
        <Text>Pont:</Text>
        <InputNumber
          min={1}
          value={point}
          onChange={value => setPoint(value)}
        />
        {type === 'multichoice' && (
          <>
            <br />
            <Text>Kiválasztható válaszok száma:</Text>
            <InputNumber
              min={1}
              value={maxAnswerCount}
              onChange={value => setMaxAnswerCount(value)}
            />
          </>
        )}
        <br />
        <Text>Címkék:</Text>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Kérem válaszon"
          onChange={handleTagChange}
          value={questionTags}
          options={tagList}
        />
        <br />
        {type !== 'custom' && (
          <>
            <Checkbox
              checked={random}
              onChange={e => {
                setRandom(e.target.checked);
              }}
            >
              {type === 'table'
                ? 'Random alkérdés sorrend'
                : 'Random válasz sorrend'}
            </Checkbox>
            <br />
            {random && (
              <>
                <Text>Random limit:</Text>
                <InputNumber
                  min={1}
                  value={randomLimit}
                  onChange={value => setRandomLimit(value)}
                />
              </>
            )}
            <br />
            <Checkbox
              checked={hideresult}
              onChange={e => {
                setHideresult(e.target.checked);
              }}
            >
              Eredmény elrejtése
            </Checkbox>
            <br />
            <Checkbox
              checked={webFeatured}
              onChange={e => {
                setWebFeatured(e.target.checked);
              }}
            >
              Weben kiemelt
            </Checkbox>
            {type === 'table' && (
              <>
                <br />
                <Checkbox
                  checked={subQuestionSkippable}
                  onChange={e => {
                    setSubQuestionSkippable(e.target.checked);
                  }}
                >
                  Opcionális válaszadás
                </Checkbox>
                {!!subQuestionSkippable && (
                  <>
                    <br />
                    <Text>"Nem tudom" gomb szövege:</Text>
                    <Input
                      placeholder="Nem tudom"
                      value={skipText}
                      onChange={e => setSkipText(e.target.value)}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
      <div className="question-condition-container">
        {' '}
        <Title level={4}>Feltétel</Title>
        <QuestionConditionEditor
          initialQuestion={initialQuestion}
          conditions={conditions}
          questions={questions}
          validateCondition={validateCondition}
          onChange={condition => {
            const index = conditions.findIndex(
              c =>
                c.groupNo === condition.groupNo &&
                c.sequenceNo === condition.sequenceNo
            );

            if (index === -1) {
              setConditions([...conditions, condition]);
            } else {
              const newConditions = [...conditions];
              newConditions[index] = condition;
              setConditions(newConditions);
            }
          }}
          onRemove={conditionsIn => {
            const newConditions = conditions.filter(
              c =>
                !conditionsIn.find(
                  cIn =>
                    c.groupNo === cIn.groupNo && c.sequenceNo === cIn.sequenceNo
                )
            );

            const groupNos = newConditions
              .map(c => c.groupNo)
              .filter((v, i, a) => a.indexOf(v) === i)
              .sort((a, b) => a - b);

            for (let i = 0; i < groupNos.length; i++) {
              const groupNo = groupNos[i];

              const groupConditions = newConditions
                .filter(c => c.groupNo === groupNo)
                .sort((a, b) => a.sequenceNo - b.sequenceNo);

              for (let j = 0; j < groupConditions.length; j++) {
                const condition = groupConditions[j];
                condition.groupNo = i;
                condition.sequenceNo = j;
              }
            }

            setConditions(newConditions);
          }}
        />
      </div>
      {type === 'table' && (
        <div className="sub-question-container">
          <Title level={4}>Alkérdések</Title>
          {subQuestions.map((subQuestion, idx) => (
            <React.Fragment key={idx}>
              <SubQuestionItem
                subQuestion={subQuestion}
                handleChange={subQuestionChange}
                handleOrder={subQuestionOrder}
                handleRemove={subQuestionRemove}
              />
              <Divider />
            </React.Fragment>
          ))}
          <Button
            type="danger"
            onClick={() =>
              setSubQuestions([
                ...subQuestions,
                { content: '', order: subQuestions.length },
              ])
            }
          >
            <PlusOutlined /> Alkérdés hozzáadása
          </Button>
        </div>
      )}
      {type !== 'custom' && (
        <div className="answer-container">
          <Title level={4}>Válaszok</Title>
          {!!answers.length && (
            <Button className="answer-copy__btn" onClick={copyAnswers}>
              Válaszok másolása
            </Button>
          )}
          {!!copiedAnswers?.length && (
            <Button className="answer-copy__btn" onClick={injectAnswers}>
              Válaszok beillesztése
            </Button>
          )}
          {answers.map((answer, idx) => (
            <React.Fragment key={idx}>
              <AnswerItem
                answer={answer}
                questionType={type}
                handleChange={answerChange}
                handleRemove={answerRemove}
                handleOrder={answerOrder}
                answers={answers}
              />
              <Divider />
            </React.Fragment>
          ))}
          {(!hideAddAnswerButton || type !== 'table') && (
            <Button
              type="danger"
              onClick={() =>
                setAnswers([...answers, { content: '', order: answers.length }])
              }
            >
              <PlusOutlined /> Válasz hozzáadása
            </Button>
          )}
        </div>
      )}
      <div className="btn-container">
        <Button type="primary" onClick={submitQuestion} loading={loading}>
          Mentés
        </Button>
      </div>
    </Drawer>
  );
};

export default QuestionDrawer;
